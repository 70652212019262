import React from "react"
import Layout from "../components/Layout"
import AllProjects from "../components/AllProjects"
import SEOOptimze from "../components/SEOmeta"

const Projects = () => {
  return (
    <Layout>
      <SEOOptimze title="Projects" />
      <main className="page">
        <AllProjects />
      </main>
    </Layout>
  )
}

export default Projects
